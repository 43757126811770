<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('EditInformation')"
    v-on:submit="saveInfo"
  >
    <template v-slot:content>
      <v-form ref="form" v-model="form" lazy-validation>
        <v-menu
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-top="30"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :label="$t('Date')"
              :rules="[(v) => !!v || '']"
              v-model="date"
              outlined
              :disabled="is_saving"
              prepend-inner-icon="$vuetify.icons.fasCalendarAlt"
              v-on="on"
              required
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            :max="max_date"
            min="1980-01-01"
            @input="date_menu = false"
          ></v-date-picker>
        </v-menu>

        <error-box :error="error"></error-box>
      </v-form>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "EditInformation": "Edit date",
    "Date": "Date",
    "Time": "Time",
    "InfoSaved": "Information updated!"
    },
    "sv": {
    "EditInformation": "Redigera datum",
    "Date": "Datum",
    "Time": "Klockan",
    "InfoSaved": "Informationen sparades!"
    }
    }
</i18n>

<script>
import { mapActions } from "vuex";
import * as moment from "moment";

export default {
  name: "media_date_edit_dialog",
  computed: {},
  data: () => ({
    error: null,

    media: null,
    post: null,

    date: "",
    max_date: null,

    is_open: false,
    is_saving: false,
    form: false,

    date_menu: null,
  }),
  created: function () {},
  methods: {
    ...mapActions({
      updateMediaDate: "diary/albums/updateMediaDate",
      updatePostDate: "diary/news/updatePublishDate",
    }),

    editMediaDate(media) {
      this.media = { ...media };
      var date = moment(media.create_date);
      this.max_date = moment(date).format("YYYY-MM-DD");

      if (media.backdated_time) date = moment(media.backdated_time);

      this.date = moment(date).format("YYYY-MM-DD");
      this.is_open = true;
    },
    editPostDate(post) {
      this.post = { ...post };
      this.date = moment(post.show_date).format("YYYY-MM-DD");
      this.is_open = true;
    },

    saveInfo() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        if (self.media) {
          self.saveMediaDate();
        } else if (self.post) {
          self.savePostDate();
        }
      }
    },
    saveMediaDate() {
      var self = this;
      self
        .updateMediaDate({
          media_id: self.media.id,
          date: self.date,
        })
        .then(function () {
          self.$successNoty(self.$t("InfoSaved"));
          self.is_saving = false;
          self.is_open = false;
          self.media.backdated_time = self.date;
          self.$emit("updated", self.media);
        })
        .catch(function (error) {
          self.error = error;
          self.is_saving = false;
        });
    },
    savePostDate() {
      var self = this;
      self
        .updatePostDate({
          id: self.post.id,
          date: self.date,
        })
        .then(function () {
          self.$successNoty(self.$t("InfoSaved"));
          self.is_saving = false;
          self.is_open = false;
          self.post.show_date = self.date;
          self.$emit("updated", self.post);
        })
        .catch(function (error) {
          self.error = error;
          self.is_saving = false;
        });
    },
  },
};
</script>